<template>
  <div id="app">
    <el-row>
      <el-col :lg="6" :md="8" :sm="12" :xs="24" v-for="(item, index) in videoList" :key="index">
        <el-tag
            closable
            :disable-transitions="false"
            @close="handleClose(index)">
          {{ item.name }}
          <ArtPlayer :videoId="'video_charon' + index" :url="item.source" />
        </el-tag>
      </el-col>
    </el-row>
    <el-button class="button-new-tag" size="small" @click="dialogVisible = true">+ New Tag</el-button>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="80%">
      <el-form label-width="100px" class="demo-ruleForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="videoInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="name">
          <el-input v-model="videoInfo.source"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addVideoPlay()">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import ArtPlayer from "../../../components/Artplayer.vue";

export default {
  components: {
    ArtPlayer,
  },
  name: "videoPlayers",
  data() {
    return {
      inputVisible: false,
      inputValue: '',
      videoList: [],
      dialogVisible: false,
      videoInfo: {
        name: "",
        source: ""
      }
    }
  },
  methods: {
    addVideoPlay() {
      const is = this.videoList.some(ele => ele.name === this.videoInfo.name)
      if (!is) {
        const obj = {
          name: this.videoInfo.name,
          source: this.videoInfo.source
        }
        this.videoList.push(obj);
        this.dialogVisible = false;
      } else {
        this.$message.error('名称重复');
      }
    },
    handleClose(index) {
      this.videoList.splice(index, 1);
    },
  }
}
</script>

<style>
.el-tag {
  width: 100%;
}
</style>